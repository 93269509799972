import React from "react"
import styled from "@emotion/styled"
import { Box } from "theme-ui"

const DividerContainer = styled(Box)`
  &.small {
    width: ${(props) => (props.verticalAlign ? "30px" : "100%")};
    height: ${(props) => (props.verticalAlign ? "100%" : "30px")};
  }
  &.medium {
    width: ${(props) => (props.verticalAlign ? "60px" : "100%")};
    height: ${(props) => (props.verticalAlign ? "100%" : "60px")};
  }
  &.large {
    width: ${(props) => (props.verticalAlign ? "120px" : "100%")};
    height: ${(props) => (props.verticalAlign ? "100%" : "120px")};
  }
`

const Divider = ({ size, verticalAligned }) => {
  switch (size) {
    case 1:
      return (
        <DividerContainer
          className="small"
          verticalAlign={verticalAligned}
        ></DividerContainer>
      )
    case 2:
      return (
        <DividerContainer
          className="medium"
          verticalAlign={verticalAligned}
        ></DividerContainer>
      )
    case 3:
      return (
        <DividerContainer
          className="large"
          verticalAlign={verticalAligned}
        ></DividerContainer>
      )
    default:
      return (
        <DividerContainer
          className="small"
          verticalAlign={verticalAligned}
        ></DividerContainer>
      )
  }
}

export default Divider
