import React from "react"
import styled from "@emotion/styled"
import { renderRichText } from "gatsby-source-contentful/rich-text"

const RichTextContainer = styled.div`
  font-style: normal;
  font-weight: normal;

  h1 {
    font-size: ${({ theme }) => theme.fontSizes[4]}px;
    line-height: ${({ theme }) => theme.fontSizes[4]}px;
  }

  h2 {
    font-size: ${({ theme }) => theme.fontSizes[3]}px;
    line-height: ${({ theme }) => theme.fontSizes[3]}px;
  }

  p {
    font-size: ${({ theme }) => theme.fontSizes[1]}px;
    line-height: 28px;
  }

  a {
    text-decoration: none;
    color: inherit;
  }

  text-align: ${(props) => (props.isCenter ? "center" : "left")};
`

const RichText = ({ centeredText, input }) => {
  return (
    <RichTextContainer isCenter={centeredText}>
      {input ? renderRichText(input) : ""}
    </RichTextContainer>
  )
}

export default RichText
