import React from "react"
import styled from "@emotion/styled"
import { Link } from "gatsby"
import Image from "../base/image"

const StoryCard = styled(Link)`
  display: block;
  width: 100%;
  text-decoration: none;
  color: ${({ theme }) => theme.colors.darkGreen};

  .isHighlighted & {
    color: #172f20;
  }
`

/*
const StoryCardDate = styled.p`
  margin-bottom: 10px;
  font-size: ${({ theme }) => theme.fontSizes[0]}px;
  text-transform: uppercase;

  ${({ theme }) => theme.bp.desktop} {
    font-size: ${({ theme }) => theme.fontSizes[1]}px;
  }
`
*/

const StoryCardImage = styled(Image)`
  width: 100%;
  height: 320px;
  margin-bottom: 10px;
  overflow: hidden;

  ${({ theme }) => theme.bp.desktop} {
    margin-bottom: 20px;
    height: auto;
  }
`

const StoryCardText = styled.div`
  ${({ theme }) => theme.bp.largeDesktop} {
    display: flex;
  }
`

const StoryCardTitle = styled.h3`
  flex: 1 0 0;
  margin-bottom: 0;
  padding-right: 20px;
  font-size: ${({ theme }) => theme.fontSizes[2]}px;
  line-height: 1.5;
  font-weight: normal;

  ${({ theme }) => theme.bp.desktop} {
    &:not(:only-child) {
      font-size: ${({ theme }) => theme.fontSizes[4]}px;
    }
  }

  ${({ theme }) => theme.bp.largeDesktop} {
    padding-right: 30px;
    font-size: ${({ theme }) => theme.fontSizes[3]}px;

    &:not(:only-child) {
      font-size: ${({ theme }) => theme.fontSizes[5]}px;
    }
  }
`

const StoryCardExcerpt = styled.p`
  display: none;
  flex: 1 0 0;
  margin-top: 10px;
  margin-bottom: 0;
  font-size: ${({ theme }) => theme.fontSizes[2]}px;

  ${({ theme }) => theme.bp.desktop} {
    display: block;
  }

  ${({ theme }) => theme.bp.largeDesktop} {
    margin-top: 0;
  }
`

const storyCard = ({input}) => {
	const { slug, thumbnail, title, author, excerpt } = input

	return (
    <StoryCard to={`/stories/${slug}`}>
			<StoryCardImage primary={thumbnail}/>
			<StoryCardText>
				<StoryCardTitle>
					{title} { author && `by ${author}`}
				</StoryCardTitle>
				{excerpt &&
					<StoryCardExcerpt>
						{excerpt}
					</StoryCardExcerpt>
				}
			</StoryCardText>
    </StoryCard>
  )
}

export default storyCard
