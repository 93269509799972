import styled from "@emotion/styled"
import { navigate } from "gatsby"
import React, { useContext } from "react"
import { Box, Flex, Text } from "theme-ui"
import { InterfaceContext } from "../../context/interface-context"
import useDisableBackgroundScroll from "../../hooks/useDisableBackgroundScroll"
import { handleMedia } from "../../util/handlers"
import Button from "../base/buttons"
import { CloseContainer } from "../drawers/modal-drawer"
import RichText from "../rich-text"

const Container = styled(Box)`
  height: 100%;
  width: 100%;
  flex: 1;
`

const Information = styled(Flex)`
  width: 100%;
  height: 100%;
  padding: 0 ${(props) => props.theme.space[1]}px;
  padding-top: 15px;
  flex-direction: column;
  justify-content: center;
`

const InformationInner = styled(Flex)`
  flex-direction: column;
`

const Tile = ({ input, zoomOnImage }) => {
  const { backgroundImage, body, cta, linkTo } = input
  const { toggleModal, setModalContent } = useContext(InterfaceContext)
  const [, setScrollable] = useDisableBackgroundScroll()
  return (
    <Container>
      {backgroundImage &&
        handleMedia(backgroundImage, {
          imageClick: () => {
            if (linkTo) {
              navigate(linkTo)
            } else if (zoomOnImage) {
              setModalContent(
                <>
                  <CloseContainer
                    onClick={() => {
                      setScrollable(true)
                      toggleModal()
                    }}
                  >
                    <Text>Close</Text>
                  </CloseContainer>
                  <Flex sx={{ flexDirection: "column", overflow: "scroll" }}>
                    {handleMedia(backgroundImage, {
                      imageClick: () => {
                        setScrollable(true)
                        toggleModal()
                      },
                    })}
                  </Flex>
                </>
              )
              setScrollable(false)
              toggleModal()
            }
          },
        })}
      <Information>
        {(body || cta) && (
          <InformationInner>
            {body && <RichText input={body} />}
            {cta && (
              <Button
                variant="primary"
                onClick={() => navigate(linkTo)}
                sx={{ height: "40px" }}
              >
                <Text sx={{ fontSize: [0, 1] }} pt="2px">
                  {cta}
                </Text>
              </Button>
            )}
          </InformationInner>
        )}
      </Information>
    </Container>
  )
}

export default Tile
