import React from "react"
import styled from "@emotion/styled"
import { Link } from "gatsby"
import Image from "../base/image"

const Featured = styled(Link)`
  display: block;
  position: relative;
`

const FeaturedImage = styled(Image)`
  display: block;
  width: 100%;
  position: relative;

  .gatsby-image-wrapper {
    max-height: calc(100vh - ${({ theme }) => theme.height[1]}px);
  }
`

const FeaturedContent = styled.div`
  width: 100%;
  padding: 20px 30px;
  position: absolute;
  bottom: 0;

  ${({ theme }) => theme.bp.largeDesktop} {
    padding: 50px 40px;
  }
`

const FeaturedTitle = styled.h2`
  margin-bottom: 15px;
  font-size: ${(props) =>
    props?.size ? props.size : ({ theme }) => theme.fontSizes[6]}px;
  color: ${(props) =>
    props?.color ? props.color : ({ theme }) => theme.colors.yellow};

  ${({ theme }) => theme.bp.largeDesktop} {
    margin-bottom: 30px;
    font-size: ${(props) =>
      props?.size ? props.size : ({ theme }) => theme.fontSizes[7]}px;
  }
`

const FeaturedButton = styled(Link)`
  display: inline-block;
  min-width: 160px;
  padding: 12px 20px;
  background-color: ${(props) =>
    props?.backgroundColor
      ? props.backgroundColor
      : ({ theme }) => theme.colors.yellow};
  border-radius: 26px;
  font-size: ${({ theme }) => theme.fontSizes[2]}px;
  text-align: center;
  text-decoration: none;
  color: ${(props) =>
    props?.textColor ? props.textColor : ({ theme }) => theme.colors.darkGreen};
  transition: background-color 0.2s ease-in-out, color 0.2s ease-in-out;

  ${({ theme }) => theme.bp.largeDesktop} {
    min-width: 180px;
    font-size: ${({ theme }) => theme.fontSizes[3]}px;
  }
`
const featured = ({ input }) => {
  const {
    backgroundImage,
    title,
    titleSize,
    titleColor,
    cta,
    linkTo,
    linkBackgroundColor,
    linkTextColor,
  } = input

  return (
    <Featured to={linkTo}>
      {backgroundImage && <FeaturedImage primary={backgroundImage} />}
      <FeaturedContent>
        <FeaturedTitle size={titleSize} color={titleColor}>
          {title}
        </FeaturedTitle>
        {linkTo && cta &&(
          <FeaturedButton
            to={linkTo}
            backgroundColor={linkBackgroundColor}
            textColor={linkTextColor}
          >
            {cta}
          </FeaturedButton>
        )}
      </FeaturedContent>
    </Featured>
  )
}

export default featured
