import styled from "@emotion/styled"
import { navigate } from "gatsby-link"
import React, { useContext } from "react"
import { Flex } from "theme-ui"

import { InterfaceContext } from "../../context/interface-context"
import useDisableBackgroundScroll from "../../hooks/useDisableBackgroundScroll"
import { handleMedia } from "../../util/handlers"
import Text from "../base/text/text"
import { CloseContainer } from "../drawers/modal-drawer"
import RichText from "../rich-text"

const HeroContainer = styled(Flex)`
  width: ${(props) => (props.centered ? "60%" : "100%")};
  margin: ${(props) =>
    props.centered ? `${props.theme.space[4]}px auto` : "none"};

  flex-direction: column;
`

const Hero = ({ input, zoomOnImage }) => {
  const { media, description, centered, linkTo } = input
  const { toggleModal, setModalContent } = useContext(InterfaceContext)
  const [, setScrollable] = useDisableBackgroundScroll()

  return (
    <HeroContainer centered={centered}>
      {description && <RichText body={description} />}
      <Flex sx={{ flex: 1 }}>
        {handleMedia(media, {
          imageClick: () => {
            if (linkTo) {
              navigate(linkTo)
            }
            if (!zoomOnImage) return
            setModalContent(
              <>
                <CloseContainer
                  onClick={() => {
                    setScrollable(true)
                    toggleModal()
                  }}
                >
                  <Text>Close</Text>
                </CloseContainer>
                <Flex sx={{ flexDirection: "column", overflow: "scroll" }}>
                  {handleMedia(media, {
                    imageClick: () => {
                      setScrollable(true)
                      toggleModal()
                    },
                  })}
                </Flex>
              </>
            )
            setScrollable(false)
            toggleModal()
          },
        })}
      </Flex>
    </HeroContainer>
  )
}

export default Hero
