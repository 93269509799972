import React from "react"
import Divider from "../divider/divider"
import Featured from "../featured/featured"
import Hero from "../hero"
import ProductHighlight from "../product/product-highlight"
import RichText from "../rich-text"
import Tile from "../tile-section/tile"
import TileSection from "../tile-section/tile-section"
import StoryCard from "../story-card/story-card"

const ContentModules = ({ allModules, options = {} }) => {
  const { position = "", zoomOnImage = false } = options

  const module = (allModules || []).map((s, i, a) => {
    if (s.internal?.type) {
      switch (s.internal.type) {
        case "ContentfulHero": {
          return <Hero key={s.id} input={s} zoomOnImage={zoomOnImage} />
        }
        case "ContentfulDivider": {
          return (
            <Divider
              key={s.id}
              size={s.size}
              verticalAligned={s.verticalAligned}
            />
          )
        }
        case "ContentfulTileSection": {
          return <TileSection key={s.id} input={s} zoomOnImage={zoomOnImage} />
        }
        case "ContentfulTile": {
          return <Tile key={s.id} input={s} />
        }
        case "ContentfulText": {
          return <RichText key={s.id} input={s.text} />
        }

        case "ContentfulFeatured": {
          if ("leftColumn" === position) {
            return <Featured key={s.id} input={s} />
          }
        }

        case "ContentfulProduct": {
          if ("featuredProducts" === position) {
            return (
              <ProductHighlight
                product={s}
                slugs={{
                  fullSlug: s.fullSlug,
                }}
                productVariants={s.variants}
              />
            )
          }

          if (s.fullSlug) {
            return (
              <ProductHighlight
                product={s}
                slugs={{
                  fullSlug: s.fullSlug,
                }}
              />
            )
          }
          const categorySlug = s.category?.[0]?.slug
          const productSlug = s.slug

          return (
            <ProductHighlight
              key={s.id}
              product={s}
              slugs={{
                categorySlug: categorySlug && categorySlug,
                productSlug: productSlug,
              }}
            />
          )
        }
        case "ContentfulStory": {
          if ("rightColumn" === position) {
            return <StoryCard key={s.id} input={s} />
          }

          return <></>
        }

        default:
          return null
      }
    } else {
      console.log(s)
    }
  })

  return module
}

export default ContentModules
