import React from "react"
import styled from "@emotion/styled"
import { Flex } from "theme-ui"

import Tile from "./tile"
import Divider from "../divider/divider"

const TilesContainer = styled(Flex)`
  align-items: ${(props) => (props.centered ? "center" : "flex-start")};
  display: flex;
  flex-direction: ${(props) => props.tilesDirection};

  width: ${(props) => (props.centered ? "60%" : "100%")};
  margin: ${(props) =>
    props.centered ? `${props.theme.space[4]}px auto` : "none"};

  > div:first-of-type {
    margin-bottom: 4px;
  }

  > div:last-of-type {
    margin-top: 4px;
  }

  ${(props) => props.theme.bp.desktop} {
    flex-direction: ${(props) => (props.verticalAlign ? "column" : "row")};
    > div:first-of-type {
      margin-right: 4px;
      margin-bottom: 0px;
    }

    > div:last-of-type {
      margin-top: 0px;
      margin-left: 4px;
    }
  }
`

const TileSection = ({ input, zoomOnImage }) => {
  const { id, withVerticalAlignment, tiles, centered } = input

  const getTilesDirection = () => {
    const firstTileImage = tiles[0].backgroundImage
    const secondTileImage = tiles[1].backgroundImage

    if (firstTileImage && !secondTileImage) {
      return "column"
    } else if (!firstTileImage && secondTileImage) {
      return "column-reverse"
    } else {
      return "column"
    }
  }

  return (
    <TilesContainer
      key={id}
      verticalAlign={withVerticalAlignment}
      tilesDirection={getTilesDirection()}
      centered={centered}
    >
      {tiles &&
        tiles.map((tile) => {
          if (tile.internal.type === "ContentfulDivider") {
            return (
              <Divider
                size={tile.size}
                verticalAligned={tile.verticalAligned}
              />
            )
          }

          return <Tile key={tile.id} input={tile} zoomOnImage={zoomOnImage} />
        })}
    </TilesContainer>
  )
}

export default TileSection
